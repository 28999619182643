import request from '@/core/services/api.services.request'

export function getAllRoles(params) {
  return request({
    url: '/role/list',
    method: 'get',
    params: params
  })
}

export function getRoleDetails(role_id) {
  return request({
    url: '/role/view/'+role_id,
    method: 'get'
  })
}

export function deleteRole(role_id) {
  return request({
    url: '/role/delete/'+role_id,
    method: 'delete'
  })
}

export function getAllModulesList(params) {
  return request({
    url: '/module/list/all',
    method: 'get',
    params: params
  })
}

export function getAllModules(params) {
  return request({
    url: '/module/list',
    method: 'get',
    params: params
  })
}

export function getModuleDetails(module_id) {
  return request({
    url: '/module/view/'+module_id,
    method: 'get'
  })
}

export function deleteModule(module_id) {
  return request({
    url: '/module/delete/'+module_id,
    method: 'delete'
  })
}

export function getAllScreens(params) {
  return request({
    url: '/screen/list',
    method: 'get',
    params: params
  })
}

export function getScreenDetails(screen_id) {
  return request({
    url: '/screen/view/'+screen_id,
    method: 'get'
  })
}

export function deleteScreen(screen_id) {
  return request({
    url: '/screen/delete/'+screen_id,
    method: 'delete'
  })
}

export function getModulesWithScreens() {
  return request({
    url: '/permission/withscreens',
    method: 'get'
  })
}

export function permissionListByRole(role_id) {
  return request({
    url: 'permission/list/' + role_id,
    method: 'get'
  })
}